import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Skeleton } from 'bv-components';
import { loggedIn } from 'bv-helpers/session';
import { useFetch } from 'bv-hooks';
import { resetBetslip } from '../lucky_dip_betslip/ducks/action_creators';
import fetchLuckyDipComponent from './api';
import Header from './components/header';
import LuckyDips from './components/lucky_dips';

const LuckyDipComponent = ({ componentId, eventId, isCasinoComponent }) => {
  const [loggedInOnMount] = useState(loggedIn());
  const dispatch = useDispatch();
  const [component, loading] = useFetch(
    () => fetchLuckyDipComponent({
      componentId,
      eventId,
      isCasinoComponent,
    }),
    [componentId, eventId, isCasinoComponent],
  );

  useEffect(() => () => {
    if (loggedInOnMount === loggedIn()) {
      dispatch(resetBetslip());
    }
  }, []);

  if (loading) {
    return eventId ? null : <Skeleton skeletonType="lucky-dip-wrapper" />;
  }
  if (!component.luckyDips?.length) return null;

  return (
    <section className="lucky-dip-component">
      <Header {...component.header} />
      <LuckyDips
        luckyDips={component.luckyDips}
        showEventDescription={component.showEventDescription}
      />
    </section>
  );
};

LuckyDipComponent.propTypes = {
  componentId: PropTypes.number.isRequired,
  eventId: PropTypes.number,
  isCasinoComponent: PropTypes.bool,
};

LuckyDipComponent.defaultProps = {
  eventId: null,
  isCasinoComponent: false,
};

export default LuckyDipComponent;

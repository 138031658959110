import {
  ADD_TO_BETSLIP,
  BETSLIP_SUBMIT_INIT,
  BETSLIP_SUBMIT_COMPLETE,
  RESET_BETSLIP,
  SET_STAKE,
  TOGGLE_USE_PROMO_CASH,
} from './action_types';

export const addToBetslip = (luckyDip) => ({
  type: ADD_TO_BETSLIP,
  payload: { luckyDip },
});

export const resetBetslip = () => ({
  type: RESET_BETSLIP,
});

export const setStake = (stake) => ({
  type: SET_STAKE,
  payload: { stake },
});

export const betslipSubmitInit = () => ({
  type: BETSLIP_SUBMIT_INIT,
});

export const betslipSubmitComplete = (submitResponse) => ({
  type: BETSLIP_SUBMIT_COMPLETE,
  payload: { submitResponse },
});

export const toggleUsePromocash = () => ({
  type: TOGGLE_USE_PROMO_CASH,
});

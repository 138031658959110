import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLoggedIn, useQuickDepositLink } from 'bv-hooks';
import { t } from 'bv-i18n';
import { SubmitButtonView } from 'sportsbook-betslip-components';
import { getStakeValue } from 'Betslip/helpers';
import submitButtonAttributes from 'Betslip/services/submit_button_attributes';
import { selectStake, selectStatus, selectUsePromocash } from '../ducks/selectors';

const SubmitButton = ({ balance, maxStake, onSubmit }) => {
  const loggedIn = useLoggedIn();
  const history = useHistory();
  const stake = useSelector(selectStake);
  const status = useSelector(selectStatus);
  const usePromocash = useSelector(selectUsePromocash);
  const getTotalStake = () => getStakeValue({ value: stake });
  const redirection = useQuickDepositLink();

  const buttonAttrs = submitButtonAttributes({
    balance,
    getTotalStake,
    hasAtLeastOneStake: () => getTotalStake() !== 0,
    loggedIn,
    maxStake,
    onDeposit: () => history.push(redirection),
    onLogin: () => history.push('/login'),
    onSubmit,
    stake,
    status,
    submitText: t('lucky_dip.betslip.take_lucky_dip'),
    usePromocash,
  });

  return (
    <div className="betslip__stake-button-wrapper">
      <SubmitButtonView {...buttonAttrs} />
    </div>
  );
};

SubmitButton.propTypes = {
  balance: PropTypes.instanceOf(Object).isRequired,
  maxStake: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
};

SubmitButton.defaultProps = {
  maxStake: null,
};

export default SubmitButton;

import { uniq } from 'underscore';
import sanitizeShow from './sanitizers/show';
import mergeSingles from './sanitizers/merge_singles';

const acceptSingleChanges = (singles, acceptPriceChanges, acceptLineChanges) => (
  singles.map((single) => ({
    ...single,
    ...(acceptPriceChanges ? {
      originalPriceId: single.priceId,
      originalDecimalPrice: single.decimalPrice,
      originalTextFormattedPrice: single.textFormattedPrice,
      originalOddsEW: single.oddsEW,
    } : {}),
    ...(acceptLineChanges ? {
      outcomeLineDistance: single.outcomeLineDistance,
      originalOutcomeLineDistance: single.outcomeLineDistance,
      lineFollowed: false,
    } : {}),
  }))
);

export const BETSLIP_STATUS = {
  FETCH: 'fetch',
  DELAY: 'delay',
  SUBMIT: 'submit',
  READY: 'ready',
};

// Action types
export const ADD_TO_BETSLIP_INIT = 'betslip/ADD_TO_BETSLIP_INIT';
export const MULTI_ADD_TO_BETSLIP_INIT = 'betslip/MULTI_ADD_TO_BETSLIP_INIT';
export const ADD_TO_BETSLIP_SUCCESS = 'betslip/ADD_TO_BETSLIP_SUCCESS';
export const REMOVE_FROM_BETSLIP_INIT = 'betslip/REMOVE_FROM_BETSLIP_INIT';
export const REMOVE_FROM_BETSLIP_SUCCESS = 'betslip/REMOVE_FROM_BETSLIP_SUCCESS';
export const RESET_BETSLIP_INIT = 'betslip/RESET_BETSLIP_INIT';
export const RESET_BETSLIP_SUCCESS = 'betslip/RESET_BETSLIP_SUCCESS';
export const EMPTY_BETSLIP = 'betslip/EMPTY_BETSLIP';
export const HIDE_MINI_BETSLIP = 'betslip/HIDE_MINI_BETSLIP';
export const SHOW_MINI_BETSLIP = 'betslip/SHOW_MINI_BETSLIP';
export const SET_STAKE_VALUE = 'betslip/SET_STAKE_VALUE';
export const SET_STAKE_EWS = 'betslip/SET_STAKE_EWS';
export const SET_STAKE_SP = 'betslip/SET_STAKE_SP';
export const SET_STAKES = 'betslip/SET_STAKES';
export const SET_STAKE = 'betslip/SET_STAKE';
export const CLEAR_ALL_STAKES = 'betslip/CLEAR_ALL_STAKES';
export const CLEAR_MULTIPLE_STAKES = 'betslip/CLEAR_MULTIPLE_STAKES';
export const SET_USE_PROMO_CASH = 'betslip/SET_USE_PROMO_CASH';
export const SET_BET_DELAY = 'betslip/SET_BET_DELAY';
export const BETSLIP_SUBMIT_INIT = 'betslip/BETSLIP_SUBMIT_INIT';
export const BETSLIP_SUBMIT_SUCCESS = 'betslip/BETSLIP_SUBMIT_SUCCESS';
export const BETSLIP_SUBMIT_CLEAR = 'betslip/BETSLIP_SUBMIT_CLEAR';
export const BETSLIP_UPDATE_PRICES = 'betslip/BETSLIP_UPDATE_PRICES';
export const BETSLIP_SET_TAB = 'betslip/BETSLIP_SET_TAB';
export const BETSLIP_EXPAND_MULTIPLES = 'betslip/BETSLIP_EXPAND_MULTIPLES';
export const BETSLIP_SET_STATUS = 'betslip/BETSLIP_SET_STATUS';
export const BETSLIP_REPLACE_SINGLE = 'betslip/BETSLIP_REPLACE_SINGLE';
export const BETSLIP_CANCEL_DELAY = 'betslip/BETSLIP_CANCEL_DELAY';
export const UPDATE_SINGLES_IN_PLAY_STATUS = 'betslip/UPDATE_SINGLES_IN_PLAY_STATUS';
export const DISABLE_SINGLE_EW_STATUS = 'betslip/DISABLE_SINGLE_EW_STATUS';
export const SET_SUBMIT_RESPONSE = 'betslip/SET_SUBMIT_RESPONSE';
export const CLEAR_SUBMIT_RESPONSE = 'betslip/CLEAR_SUBMIT_RESPONSE';

const emptyShowResponse = {
  singles: [],
  multiples: [],
};

// Action creators
export const addToBetslipInit = (outcomeId, opts = {}) => ({
  type: ADD_TO_BETSLIP_INIT,
  outcomeId,
  opts,
});

export const multiAddToBetslipInit = (outcomeIds, opts = {}) => ({
  type: MULTI_ADD_TO_BETSLIP_INIT,
  outcomeIds,
  opts,
});

export const addToBetslipSuccess = (outcomeIds, showResponse) => ({
  type: ADD_TO_BETSLIP_SUCCESS,
  outcomeIds,
  showResponse: showResponse ? sanitizeShow(showResponse) : emptyShowResponse,
});

export const removeFromBetslipInit = (outcomeId) => ({
  type: REMOVE_FROM_BETSLIP_INIT,
  outcomeId,
});

export const removeFromBetslipSuccess = (outcomeIds, showResponse) => ({
  type: REMOVE_FROM_BETSLIP_SUCCESS,
  outcomeIds,
  showResponse: showResponse ? sanitizeShow(showResponse) : emptyShowResponse,
});

export const resetBetslipInit = () => ({
  type: RESET_BETSLIP_INIT,
});

export const resetBetslipSuccess = (outcomeIds, showResponse, status = BETSLIP_STATUS.READY) => ({
  type: RESET_BETSLIP_SUCCESS,
  outcomeIds,
  status,
  showResponse: showResponse ? sanitizeShow(showResponse) : emptyShowResponse,
});

export const emptyBetslip = ({ clearSubmitResponse } = {}) => ({
  type: EMPTY_BETSLIP,
  clearSubmitResponse: clearSubmitResponse || false,
});

export const hideMiniBetslip = () => ({
  type: HIDE_MINI_BETSLIP,
});

export const showMiniBetslip = () => ({
  type: SHOW_MINI_BETSLIP,
});

export const setMultiplesExpanded = () => ({
  type: BETSLIP_EXPAND_MULTIPLES,
});

export const setStakes = (stakes) => ({
  type: SET_STAKES,
  stakes: Object.fromEntries(Object.entries(stakes).map(
    ([key, stake]) => ([key, { ...stake, value: stake.value.toString().replace(/^0+(\d)/, '$1') }]),
  )),
});

export const setStake = (id, { value, ews, sp }) => ({
  type: SET_STAKE,
  id,
  value: Number.isNaN(value.toString()) ? 0 : value.toString(),
  ews,
  sp,
});

export const setStakeValue = (id, value) => {
  const parsedValue = value.toString();

  return {
    type: SET_STAKE_VALUE,
    id,
    value: Number.isNaN(parsedValue) ? 0 : parsedValue,
  };
};

export const setStakeEWS = (id, ews) => ({
  type: SET_STAKE_EWS,
  id,
  ews: !!ews,
});

export const setStakeSP = (id, sp) => ({
  type: SET_STAKE_SP,
  id,
  sp: !!sp,
});

export const clearAllStakes = () => ({
  type: CLEAR_ALL_STAKES,
});

export const clearMultipleStakes = () => ({
  type: CLEAR_MULTIPLE_STAKES,
});

export const setUsePromocash = (newValue) => ({
  type: SET_USE_PROMO_CASH,
  usePromocash: newValue,
});

export const setBetDelay = (delay) => ({
  type: SET_BET_DELAY,
  delay,
});

export const betslipSubmitInit = ({ submitId, acceptPriceChanges, acceptLineChanges }) => ({
  type: BETSLIP_SUBMIT_INIT,
  acceptPriceChanges,
  acceptLineChanges,
  submitId,
});

export const betslipSubmitSuccess = (submitResponse, betIssues = {}) => ({
  type: BETSLIP_SUBMIT_SUCCESS,
  submitResponse,
  betIssues,
});

export const betslipSubmitClear = () => ({
  type: BETSLIP_SUBMIT_CLEAR,
});

export const updateBetslipPrices = (singleDeltas, multipleDeltas, acceptPriceChangesPct) => ({
  type: BETSLIP_UPDATE_PRICES,
  singleDeltas,
  multipleDeltas,
  acceptPriceChangesPct,
});

export const betslipSetTab = (tab, manual) => ({
  type: BETSLIP_SET_TAB,
  tab,
  manual,
});

export const betslipSetStatus = (status) => ({
  type: BETSLIP_SET_STATUS,
  status,
});

export const betslipReplaceSingle = (outcomeId, single, multiples) => ({
  type: BETSLIP_REPLACE_SINGLE,
  outcomeId,
  single,
  multiples,
});

export const betslipCancelDelay = () => ({
  type: BETSLIP_CANCEL_DELAY,
});

export const updateSinglesInplayStatus = (payload) => ({
  type: UPDATE_SINGLES_IN_PLAY_STATUS,
  payload,
});

export const disableSingleEWStatus = (payload) => ({
  type: DISABLE_SINGLE_EW_STATUS,
  payload,
});

export const setSubmitResponse = (payload) => ({
  type: SET_SUBMIT_RESPONSE,
  payload,
});

export const clearSubmitResponse = () => ({
  type: CLEAR_SUBMIT_RESPONSE,
});

// Reducer
const initialState = {
  outcomeIds: [],
  previousOutcomeIds: [],
  status: BETSLIP_STATUS.READY,
  showResponse: { singles: [], multiples: [] },
  submitResponse: null,
  stakes: {},
  showMiniBetslip: false,
  usePromocash: false,
  currentTab: 'singles',
  currentTabSelectedManually: false,
  multiplesExpanded: false,
  inited: false,
};

export default (state = initialState, action = {}) => {
  let stake;

  switch (action.type) {
    case RESET_BETSLIP_INIT:
      return {
        ...state,
        status: BETSLIP_STATUS.FETCH,
      };

    case RESET_BETSLIP_SUCCESS:
      return {
        ...state,
        status: action.status,
        outcomeIds: action.outcomeIds,
        showResponse: action.showResponse,
        inited: true,
        submitId: null,
      };

    case ADD_TO_BETSLIP_INIT:
      return {
        ...state,
        status: BETSLIP_STATUS.FETCH,
        submitResponse: null,
        betIssues: null,
      };

    case MULTI_ADD_TO_BETSLIP_INIT:
      return {
        ...state,
        status: BETSLIP_STATUS.FETCH,
        submitResponse: null,
        betIssues: null,
      };

    case ADD_TO_BETSLIP_SUCCESS:
      return {
        ...state,
        status: BETSLIP_STATUS.READY,
        outcomeIds: action.outcomeIds,
        showResponse: {
          ...action.showResponse,
          singles: mergeSingles(state.showResponse.singles, action.showResponse.singles),
        },
      };

    case REMOVE_FROM_BETSLIP_INIT:
      return {
        ...state,
        status: BETSLIP_STATUS.FETCH,
      };

    case REMOVE_FROM_BETSLIP_SUCCESS:
      return {
        ...state,
        status: BETSLIP_STATUS.READY,
        outcomeIds: action.outcomeIds,
        showResponse: {
          ...action.showResponse,
          singles: mergeSingles(state.showResponse.singles, action.showResponse.singles),
        },
        multiplesExpanded: action.showResponse.singles.length === 0
          ? false
          : state.multiplesExpanded,
      };

    case EMPTY_BETSLIP:
      return {
        ...state,
        outcomeIds: [],
        status: BETSLIP_STATUS.READY,
        stakes: [],
        showResponse: { singles: [], multiples: [] },
        showMiniBetslip: false,
        currentTabSelectedManually: false,
        usePromocash: false,
        betDelay: 0,
        timeoutKey: null,
        submitResponse: action.clearSubmitResponse ? null : state.submitResponse,
        betIssues: action.clearSubmitResponse ? null : state.betIssues,
        multiplesExpanded: false,
        submitId: null,
      };

    case SET_SUBMIT_RESPONSE:
      return {
        ...state,
        submitId: null,
        submitResponse: action.payload,
      };

    case SHOW_MINI_BETSLIP:
      return {
        ...state,
        showMiniBetslip: true,
      };

    case CLEAR_SUBMIT_RESPONSE:
    case HIDE_MINI_BETSLIP:
      return {
        ...state,
        showMiniBetslip: false,
        submitResponse: null,
      };

    case SET_STAKES:
      return {
        ...state,
        stakes: action.stakes,
      };

    case SET_STAKE:
      return {
        ...state,
        stakes: {
          ...state.stakes,
          [action.id]: {
            value: `${action.value}`,
            ews: !!action.ews || false,
            sp: !!action.sp || false,
          },
        },
      };

    case SET_STAKE_VALUE:
      stake = (state.stakes[action.id] || {});
      return {
        ...state,
        stakes: {
          ...state.stakes,
          [action.id]: {
            value: `${action.value}`,
            ews: stake.ews || false,
            sp: stake.sp || false,
          },
        },
      };

    case SET_STAKE_EWS:
      stake = (state.stakes[action.id] || {});
      return {
        ...state,
        stakes: {
          ...state.stakes,
          [action.id]: {
            value: stake.value || '',
            ews: action.ews,
            sp: stake.sp || false,
          },
        },
      };

    case SET_STAKE_SP:
      stake = (state.stakes[action.id] || {});
      return {
        ...state,
        stakes: {
          ...state.stakes,
          [action.id]: {
            value: stake.value || '',
            ews: stake.ews || false,
            sp: action.sp,
          },
        },
      };

    case CLEAR_ALL_STAKES:
      return {
        ...state,
        stakes: {},
      };

    case CLEAR_MULTIPLE_STAKES:
      return {
        ...state,
        stakes: Object.fromEntries(
          Object.entries(state.stakes)
            .filter((key) => !/^m-/.test(key)),
        ),
      };

    case SET_USE_PROMO_CASH:
      return {
        ...state,
        usePromocash: action.usePromocash,
      };

    case SET_BET_DELAY:
      return {
        ...state,
        status: BETSLIP_STATUS.DELAY,
        betDelay: action.delay.timeout,
        timeoutKey: action.delay.timeout_key,
      };

    case BETSLIP_SUBMIT_INIT:
      return {
        ...state,
        status: BETSLIP_STATUS.SUBMIT,
        submitResponse: null,
        submitId: action.submitId,
        showResponse: {
          ...state.showResponse,
          singles: action.acceptPriceChanges
            ? acceptSingleChanges(
              state.showResponse.singles,
              action.acceptPriceChanges,
              action.acceptLineChanges,
            ) : state.showResponse.singles,
        },
        betIssues: {},
      };

    case BETSLIP_SUBMIT_SUCCESS:
      // successful submit should clear out previous
      // betslip contents
      if (action.submitResponse && action.submitResponse.success) {
        return {
          ...state,
          status: BETSLIP_STATUS.READY,
          previousOutcomeIds: state.outcomeIds,
          outcomeIds: [],
          stakes: [],
          showResponse: { singles: [], multiples: [] },
          usePromocash: false,
          betDelay: 0,
          timeoutKey: null,
          submitResponse: action.submitResponse,
          betIssues: action.betIssues || {},
          multiplesExpanded: false,
          submitId: null,
        };
      }

      // failed submit should keep things as-is
      return {
        ...state,
        status: BETSLIP_STATUS.READY,
        submitResponse: action.submitResponse,
        betIssues: action.betIssues || {},
      };

    case BETSLIP_SUBMIT_CLEAR:
      return {
        ...state,
        submitResponse: null,
        submitId: null,
        betIssues: {},
      };

    case BETSLIP_UPDATE_PRICES:
      return {
        ...state,
        showResponse: {
          ...state.showResponse,
          singles: state.showResponse.singles.map((single) => {
            const delta = action.singleDeltas[single.outcomeId];
            if (delta) {
              return {
                ...single,
                ...delta,
              };
            }
            return single;
          }),
          multiples: state.showResponse.multiples.map((multiple) => {
            const delta = action.multipleDeltas[multiple.winBetTypeId];
            if (delta) {
              return {
                ...multiple,
                ...delta,
              };
            }
            return multiple;
          }),
        },
      };

    case BETSLIP_SET_TAB:
      return {
        ...state,
        currentTab: action.tab,
        currentTabSelectedManually: state.currentTabSelectedManually || action.manual,
      };

    case BETSLIP_EXPAND_MULTIPLES:
      return {
        ...state,
        multiplesExpanded: true,
      };

    case BETSLIP_CANCEL_DELAY:
      return {
        ...state,
        status: BETSLIP_STATUS.READY,
        betDelay: 0,
        timeoutKey: null,
        submitId: null,
      };

    case BETSLIP_SET_STATUS:
      return {
        ...state,
        status: action.status,
      };

    case BETSLIP_REPLACE_SINGLE:
      // ignore the update if the old outcome is not on the betslip anymore
      if (!state.outcomeIds.includes(action.outcomeId)) {
        return state;
      }

      return {
        ...state,
        outcomeIds: uniq([
          // remove old outcome id
          ...state.outcomeIds.filter((o) => o !== action.outcomeId),
          // add new outcome id
          action.single.outcomeId,
        ]),
        showResponse: {
          ...state.showResponse,
          multiples: action.multiples,
          singles: state.showResponse.singles.map((oldSingle) => {
            // replace old single with new single
            if (oldSingle.outcomeId === action.outcomeId) {
              if (action.outcomeId !== action.single.outcomeId) {
                return action.single;
              }
              // new and old single is the same, merge content if the new single is newer
              if ((oldSingle.timestamp || 0) < (action.single.timestamp || 0)) {
                return {
                  ...oldSingle,
                  ...action.single,
                };
              }
              return {
                ...action.single,
                ...oldSingle,
              };
            }

            // if new single already on the list, ignore it
            if (oldSingle.outcomeId === action.single.outcomeId) {
              return null;
            }

            return oldSingle;
          }).filter(Boolean),
        },
      };

    case UPDATE_SINGLES_IN_PLAY_STATUS: {
      const { eventId, inPlay } = action.payload;

      const singles = state.showResponse.singles.map((single) => (
        single.eventId === eventId && single.inRunning !== inPlay ? ({
          ...single,
          inRunning: inPlay,
        }) : single));

      return {
        ...state,
        showResponse: {
          ...state.showResponse,
          singles,
        },
      };
    }

    case DISABLE_SINGLE_EW_STATUS: {
      const { outcomeId } = action.payload;

      const singles = state.showResponse.singles.map((single) => (
        outcomeId === single.outcomeId ? {
          ...single,
          hasEW: false,
          placeTermsDeduction: 1,
        } : single));

      const stakeId = `s-${outcomeId}`;
      const currentStake = state.stakes[stakeId] || {};
      const stakes = {
        ...state.stakes,
        [stakeId]: {
          value: currentStake.value || '',
          ews: false,
          sp: currentStake.sp || false,
        },
      };

      return {
        ...state,
        showResponse: {
          ...state.showResponse,
          singles,
        },
        stakes,
      };
    }

    default:
      return state;
  }
};

import { placeBet } from '../api';
import { betslipSubmitComplete, betslipSubmitInit } from './action_creators';

export const submitBetslip = () => (dispatch, getState) => {
  dispatch(betslipSubmitInit());

  placeBet(getState().luckyDipBetslip)
    .then((response) => {
      dispatch(betslipSubmitComplete(response));
    });
};

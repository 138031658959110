import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

const emptyResponse = () => ({
  luckyDips: [],
});

const getComponentType = ({ eventId, isCasinoComponent }) => {
  if (eventId) return 'event';
  if (isCasinoComponent) return 'casino';
  return 'home';
};

export default ({ componentId, eventId, isCasinoComponent }) => {
  const componentType = getComponentType({ eventId, isCasinoComponent });
  return getJSON(`/sportsbook_components/${componentType}_components/components/${componentId}`, {
    c: locale(),
    event_id: eventId,
  }).catch(emptyResponse);
};

export const fetchLuckyDipSelections = (id) => getJSON(`/api/lucky_dips/${id}/selections`)
  .catch(() => ({ outcomeDescriptions: [] }));

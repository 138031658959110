import PropTypes from 'prop-types';
import { format as formatPrice } from 'bv-helpers/price';

const Price = ({ formattedPrice }) => (
  <div className="single-price option-column">
    <span className="odds-price">{formatPrice(formattedPrice)}</span>
  </div>
);

Price.propTypes = {
  formattedPrice: PropTypes.string.isRequired,
};

export default Price;
